import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import DelUser from './screens/DelUser';
import routes from './routes';

function App() {
  const token = localStorage.getItem('token');
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/deluser" element={<DelUser />} />
        <Route path="/dashboard" element={<Dashboard routes={routes} />}>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
          {/*<Route path={'settings'} element={<Settings />} />*/}
        </Route>
        <Route
          path="*"
          element={
            <Navigate
              to={
                token === process.env.REACT_APP_SECRET_TOKEN
                  ? '/dashboard/users'
                  : '/login'
              }
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
