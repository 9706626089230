import React, {useEffect, useState} from 'react';
import axios from 'axios';
import CarsModal from './CarsModal';

const Cars = () => {
  const [cars, setCars] = useState([]);
  const [open, setOpen] = useState(false);
  const [carInfo, setCarInfo] = useState({});
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!open) {
      setCars([]);
      setCarInfo({});
      axios
        .get(process.env.REACT_APP_API_URL + 'getCars.php', {
          headers: {
            Token: process.env.REACT_APP_SECRET_TOKEN,
          },
        })
        .then(res => {
          setCars(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [open]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Ürünler
          </h1>
        </div>
        <button
          className="hidden sm:block ml-4 bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => {
            setCarInfo({
              id: '',
              name: '',
            });
            setOpen(true);
          }}>
          Ekle
        </button>
        <input
          type="search"
          placeholder="Ara..."
          className="w-full sm:w-64 mt-4 sm:mt-0 sm:ml-4 border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          onChange={e => setSearch(e.target.value)}
        />
      </div>
      <CarsModal open={open} setOpen={setOpen} carInfo={carInfo} />
      <div className="-mx-4 mt-8 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 w-1/2">
                ID
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/2">
                İsim
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                <span className="sr-only">Düzenle</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {cars
              .filter(
                prods =>
                  !search.length ||
                  prods.name
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()),
              )
              .map((prods, index) => (
                <tr key={index} className="even:bg-gray-50">
                  <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                    {prods.id}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {prods.name}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <button
                      onClick={() => {
                        setCarInfo(prods);
                        setOpen(true);
                      }}
                      className={'text-indigo-600 hover:text-indigo-900'}>
                      Düzenle
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Cars;
