import {Fragment, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import axios from 'axios';

const ImageModal = props => {
  const [saveChassis, setSaveChassis] = useState('');

  const saveChassisNo = () => {
    let fromdata = new FormData();

    fromdata.append('id', props?.reqId);
    fromdata.append('chassisNo', saveChassis);

    axios
      .post(process.env.REACT_APP_API_URL + 'saveChassis.php', fromdata, {
        headers: {
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
      })
      .then(res => {
        if (res.data.status === 'success') {
          props.setOpen(false);
          props.setType('');
          props.setReqId('');
        } else {
          alert(res.data.message);
        }
      });
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 w-full max-w-sm p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="rounded-md bg-red-600 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => props.setOpen(false)}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="text-center">
                  <img
                    src={props.image}
                    alt="ceppar"
                    className="min-w-full rounded-lg mt-10"
                  />
                </div>
                {props.type === 'chassis' && (
                  <div className=" flex flex-col items-center mt-4">
                    <input
                      type="text"
                      className="w-full border h-8 border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Şasi No"
                      value={saveChassis}
                      onChange={e => setSaveChassis(e.target.value)}
                    />
                    <button
                      onClick={() => {
                        saveChassisNo();
                      }}
                      className="w-32 ml-4 mt-2 h-8 rounded-md bg-red-500 px-3 py-2 text-sm font-semibold  text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                      Kaydet
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ImageModal;
