import {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import axios from 'axios';

const CarsModal = props => {
  const [carsInfo, setCarsInfo] = useState({
    carId: '',
    carName: '',
  });

  useEffect(() => {
    setCarsInfo({
      carId: '',
      carName: '',
    });
    setCarsInfo({
      carId: props.carInfo?.id,
      carName: props.carInfo?.name,
    });
  }, [props.carInfo]);

  const handleAddProduct = e => {
    e.preventDefault();

    let fromdata = new FormData();
    fromdata.append('carId', carsInfo.carId);
    fromdata.append('carName', carsInfo.carName);

    axios
      .post(process.env.REACT_APP_API_URL + 'addCars.php', fromdata, {
        headers: {
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
      })
      .then(res => {
        if (res.data.status === 'Success') {
          alert('Araç kaydedildi.');
          props.setOpen(false);
        } else {
          alert(res.data.message);
        }
      });
  };

  const handleDeleteProduct = () => {
    let fromdata = new FormData();
    fromdata.append('carId', carsInfo.carId);

    axios
      .post(process.env.REACT_APP_API_URL + 'deleteCars.php', fromdata, {
        headers: {
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
      })
      .then(res => {
        if (res.data.status === 'Success') {
          alert('Araç silindi.');
          props.setOpen(false);
        } else {
          alert(res.data.message);
        }
      });
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg lg:ml-72 sm:max-w-6xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-24 sm:p-6">
                <div className="border border-dashed border-gray-900/25 px-4 py-4 mr-2">
                  <form onSubmit={handleAddProduct}>
                    <div className="mt-3 text-center flex flex-row justify-between items-center sm:mt-2">
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Araç Adı
                        </label>
                        <input
                          type="text"
                          placeholder="Araç Adı"
                          value={carsInfo.carName}
                          min={0}
                          required
                          onChange={text => {
                            setCarsInfo({
                              ...carsInfo,
                              carName: text.target.value,
                            });
                          }}
                          className="block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="mr-2 mt-2 text-center">
                      <button
                        type="submit"
                        className="my-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:text-sm">
                        {carsInfo?.carId ? 'Güncelle' : 'Ekle'}
                      </button>
                    </div>
                  </form>
                  {carsInfo?.carId && (
                    <button
                      onClick={() => {
                        handleDeleteProduct();
                      }}
                      className="my-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:text-sm">
                      Sil
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CarsModal;
