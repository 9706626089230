import {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';

const ProductsModal = props => {
  const [prodsInfo, setProdsInfo] = useState({
    productId: '',
    productName: '',
    productBrand: '',
    productImage: '',
    productImageFile: '',
    productPrice: '',
    productLPrice: '',
    productYPrice: '',
    productCPrice: '',
  });

  useEffect(() => {
    setProdsInfo({
      productId: '',
      productName: '',
      productBrand: '',
      productImage: '',
      productImageFile: '',
      productPrice: '',
      productLPrice: '',
      productYPrice: '',
      productCPrice: '',
    });
    setProdsInfo({
      productId: props.prodInfo?.productId,
      productName: props.prodInfo?.productName,
      productBrand: props.prodInfo?.productBrand,
      productImage: props.prodInfo?.productImage,
      productImageFile: '',
      productPrice: props.prodInfo?.productPrice,
      productLPrice: props.prodInfo?.productLPrice,
      productYPrice: props.prodInfo?.productYPrice,
      productCPrice: props.prodInfo?.productCPrice,
    });
  }, [props.prodInfo]);

  const handleAddProduct = e => {
    e.preventDefault();

    let fromdata = new FormData();
    fromdata.append('prodId', prodsInfo.productId);
    fromdata.append('prodName', prodsInfo.productName);
    fromdata.append('prodBrand', prodsInfo.productBrand);
    fromdata.append('prodImage', prodsInfo.productImageFile);
    fromdata.append('prodPrice', prodsInfo.productPrice);
    fromdata.append('prodLPrice', prodsInfo.productLPrice);
    fromdata.append('prodYPrice', prodsInfo.productYPrice);
    fromdata.append('prodCPrice', prodsInfo.productCPrice);

    axios
      .post(process.env.REACT_APP_API_URL + 'addProd.php', fromdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
      })
      .then(res => {
        if (res.data.status === 'Success') {
          alert('Ürün kaydedildi.');
          props.setOpen(false);
        } else {
          alert(res.data.message);
        }
      });
  };

  const ResizerFile = (file, result) => {
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      'jpeg',
      50,
      0,
      uri => {
        setProdsInfo({
          ...prodsInfo,
          productImage: result,
          productImageFile: uri,
        });
      },
      'file',
    );
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg lg:ml-72 sm:max-w-6xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-24 sm:p-6">
                <div className="border border-dashed border-gray-900/25 px-4 py-4 mr-2">
                  <form onSubmit={handleAddProduct}>
                    <div className="mt-3 text-center flex flex-row justify-between items-center sm:mt-2">
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Ürün Kodu
                        </label>
                        <input
                          type="text"
                          placeholder="Ürün Kodu"
                          value={prodsInfo.productId}
                          min={0}
                          required
                          onChange={text => {
                            setProdsInfo({
                              ...prodsInfo,
                              productId: text.target.value,
                            });
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Ürün Adı
                        </label>
                        <input
                          type="text"
                          placeholder="Ürün Adı"
                          required
                          value={prodsInfo.productName}
                          onChange={text => {
                            setProdsInfo({
                              ...prodsInfo,
                              productName: text.target.value,
                            });
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Ürün Markası
                        </label>
                        <input
                          type="text"
                          placeholder="Ürün Markası"
                          required
                          value={prodsInfo.productBrand}
                          onChange={text => {
                            setProdsInfo({
                              ...prodsInfo,
                              productBrand: text.target.value,
                            });
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div
                        className="mr-2 mt-2 w-full"
                        style={{
                          textAlign: '-webkit-center',
                        }}>
                        {!prodsInfo.productImage ? (
                          <label className="text-indigo-600 cursor-pointer ml-2">
                            Foto Yükle
                            <input
                              type="file"
                              className="sr-only"
                              onChange={e => {
                                if (e.target.files && e.target.files[0]) {
                                  let reader = new FileReader();
                                  let rFile = e.target.files[0];
                                  reader.onloadend = () => {
                                    ResizerFile(rFile, reader.result);
                                  };
                                  reader.readAsDataURL(rFile);
                                }
                              }}
                            />
                          </label>
                        ) : (
                          <img
                            src={prodsInfo.productImage}
                            alt="product"
                            className="h-14 w-14 rounded-full bg-gray-50 cursor-pointer"
                          />
                        )}
                      </div>
                    </div>
                    <div className="mt-3 text-center flex flex-row justify-between sm:mt-5">
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Orjinal Fiyat
                        </label>
                        <input
                          type="number"
                          placeholder="Orjinal Fiyat"
                          value={prodsInfo.productPrice}
                          min={0}
                          onChange={text => {
                            setProdsInfo({
                              ...prodsInfo,
                              productPrice: text.target.value,
                            });
                          }}
                          onKeyDown={e => {
                            if (
                              e.key === 'E' ||
                              e.key === 'e' ||
                              e.key === ',' ||
                              e.key === '-' ||
                              e.key === '+'
                            ) {
                              e.preventDefault();
                            }
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          L.Orjinal Fiyat
                        </label>
                        <input
                          type="number"
                          placeholder="L.Orjinal Fiyat"
                          value={prodsInfo.productLPrice}
                          min={0}
                          onChange={text => {
                            setProdsInfo({
                              ...prodsInfo,
                              productLPrice: text.target.value,
                            });
                          }}
                          onKeyDown={e => {
                            if (
                              e.key === 'E' ||
                              e.key === 'e' ||
                              e.key === ',' ||
                              e.key === '-' ||
                              e.key === '+'
                            ) {
                              e.preventDefault();
                            }
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Yan Sanayi Fiyat
                        </label>
                        <input
                          type="number"
                          placeholder="Yan Sanayi Fiyat"
                          value={prodsInfo.productYPrice}
                          min={0}
                          onChange={text => {
                            setProdsInfo({
                              ...prodsInfo,
                              productYPrice: text.target.value,
                            });
                          }}
                          onKeyDown={e => {
                            if (
                              e.key === 'E' ||
                              e.key === 'e' ||
                              e.key === ',' ||
                              e.key === '-' ||
                              e.key === '+'
                            ) {
                              e.preventDefault();
                            }
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Çıkma Fiyat
                        </label>
                        <input
                          type="number"
                          placeholder="Çıkma Fiyat"
                          value={prodsInfo.productCPrice}
                          min={0}
                          onChange={text => {
                            setProdsInfo({
                              ...prodsInfo,
                              productCPrice: text.target.value,
                            });
                          }}
                          onKeyDown={e => {
                            if (
                              e.key === 'E' ||
                              e.key === 'e' ||
                              e.key === ',' ||
                              e.key === '-' ||
                              e.key === '+'
                            ) {
                              e.preventDefault();
                            }
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="mr-2 mt-2 text-center">
                      <button
                        type="submit"
                        className="my-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:text-sm">
                        {prodsInfo?.productId ? 'Güncelle' : 'Ekle'}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ProductsModal;
