import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Modal from './dashScreen/Modal';

const Login = () => {
  const [userInfo, setUserInfo] = useState({
    username: '',
    password: '',
  });

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token') === process.env.REACT_APP_SECRET_TOKEN) {
      navigate('/dashboard/users');
    }
  }, [navigate]);

  const handleLogin = e => {
    e.preventDefault();

    axios
      .post(
        process.env.REACT_APP_API_URL + 'logUser.php',
        JSON.stringify(userInfo),
        {
          headers: {
            Token: process.env.REACT_APP_SECRET_TOKEN,
          },
        },
      )
      .then(response => {
        if (response.data.status === 'Success') {
          localStorage.setItem('token', response.data.token);
          navigate('/dashboard/users');
        } else {
          setOpen(true);
        }
      });
  };

  return (
    <div className="h-screen bg-white">
      <div className="flex min-h-full flex-1">
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1613279214283-160447074e3b?q=80&w=2993&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=cover&w=1080&q=80"
            alt=""
          />
        </div>
        <Modal
          open={open}
          setOpen={setOpen}
          text={{
            title: 'Giriş Başarısız',
            desc: 'Kullanıcı adı veya şifre hatalı. Lütfen tekrar deneyiniz!',
          }}
        />
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-8 text-3xl font-bold leading-9 tracking-tight text-black">
                CEPPAR YÖNETİM PANELİ
              </h2>
            </div>

            <div className="mt-10">
              <div>
                <form onSubmit={handleLogin} className="space-y-6">
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Kullanıcı Adı
                    </label>
                    <div className="mt-2">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        value={userInfo.username}
                        onChange={e => {
                          setUserInfo({...userInfo, username: e.target.value});
                        }}
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Şifre
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        value={userInfo.password}
                        onChange={e =>
                          setUserInfo({...userInfo, password: e.target.value})
                        }
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-red-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                      Giriş Yap
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
