import {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import axios from 'axios';

const UserModal = props => {
  const [users, setUsers] = useState({
    id: '',
    name: '',
    email: '',
    phone: '',
    pwd: '',
    ban: '',
    adress: '',
    taxNo: '',
    taxOffice: '',
  });

  useEffect(() => {
    setUsers({
      id: props.userInfo?.id,
      name: props.userInfo?.name,
      email: props.userInfo?.email,
      phone: props.userInfo?.phone,
      pwd: '',
      ban: props.userInfo?.ban,
      adress: props.userInfo?.adress,
      taxNo: props.userInfo?.taxNo,
      taxOffice: props.userInfo?.taxOffice,
    });
  }, [props.userInfo]);

  const saveInfo = e => {
    e.preventDefault();

    let fromdata = new FormData();

    fromdata.append('id', users?.id);
    fromdata.append('name', users?.name);
    fromdata.append('phone', users?.phone);
    fromdata.append('pwd', users?.pwd);
    fromdata.append('ban', users?.ban);
    fromdata.append('adress', users?.adress);
    fromdata.append('taxNo', users?.taxNo);
    fromdata.append('taxOffice', users?.taxOffice);

    axios
      .post(process.env.REACT_APP_API_URL + 'updateUserInfo.php', fromdata, {
        headers: {
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
      })
      .then(res => {
        if (res.data.status === 'success') {
          alert(res.data.message);
          props.setOpen(false);
        } else {
          alert(res.data.message);
        }
      });
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg lg:ml-72 sm:max-w-6xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-24 sm:p-6">
                <form onSubmit={saveInfo}>
                  <div className="shadow sm:overflow-hidden sm:rounded-md">
                    <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
                      <div>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                          Kullanıcı Bilgileri
                        </h3>
                      </div>

                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            İsim
                          </label>
                          <input
                            type="text"
                            value={users?.name}
                            onChange={e => {
                              setUsers({...users, name: e.target.value});
                            }}
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            E-Mail
                          </label>
                          <input
                            type="text"
                            value={users?.email}
                            disabled
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Telefon
                          </label>
                          <input
                            type="text"
                            value={users?.phone}
                            onChange={e => {
                              setUsers({...users, phone: e.target.value});
                            }}
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Şifre
                          </label>
                          <input
                            type="text"
                            value={users?.pwd}
                            onChange={e => {
                              setUsers({...users, pwd: e.target.value});
                            }}
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Engelleme
                          </label>
                          <input
                            type="checkbox"
                            checked={users?.ban === '1' ? true : false}
                            onChange={e => {
                              setUsers({
                                ...users,
                                ban: e.target.checked ? '1' : '0',
                              });
                            }}
                            className="w-full mt-2 block rounded py-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            TC/Vergi No
                          </label>
                          <input
                            type="text"
                            value={users?.taxNo}
                            onChange={e => {
                              setUsers({
                                ...users,
                                taxNo: e.target.value,
                              });
                            }}
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Vergi Dairesi
                          </label>
                          <input
                            type="text"
                            value={users?.taxOffice}
                            onChange={e => {
                              setUsers({
                                ...users,
                                taxOffice: e.target.value,
                              });
                            }}
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>

                        <div className="col-span-6">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Adres
                          </label>
                          <input
                            type="text"
                            value={users?.adress}
                            onChange={e => {
                              setUsers({...users, adress: e.target.value});
                            }}
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 text-right sm:px-6">
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Kaydet
                      </button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UserModal;
