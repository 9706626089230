import {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import axios from 'axios';

const ReqCModal = props => {
  const [shipInfo, setShipInfo] = useState({
    shipName: '',
    shipNo: '',
  });

  const [payInfo, setPayInfo] = useState([]);

  useEffect(() => {
    setShipInfo({
      shipName: '',
      shipNo: '',
    });
    axios
      .get(process.env.REACT_APP_API_URL + 'getModalInfo.php', {
        headers: {
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
        params: {
          reqId: props?.reqId,
        },
      })
      .then(res => {
        setPayInfo(res.data);
      })
      .catch(err => {
        console.log(err);
      });
    setShipInfo({
      shipName: props?.shipInfo.shipName,
      shipNo: props?.shipInfo.shipNo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reqId]);

  const handleShipInfo = async () => {
    let fromdata = new FormData();
    fromdata.append('shipName', shipInfo.shipName);
    fromdata.append('shipNo', shipInfo.shipNo);
    fromdata.append('reqId', props.reqId);
    await axios
      .post(process.env.REACT_APP_API_URL + 'saveShipInfo.php', fromdata, {
        headers: {
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
      })
      .then(res => {
        if (res.data.status === 'Success') {
          alert('Nakliye bilgileri kaydedildi.');
          props.setOpen(false);
        } else {
          alert(res.data.message);
        }
      });
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg lg:ml-72 sm:max-w-6xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-24 sm:p-6">
                <div className="-mx-4 mt-8 flow-root sm:mx-0">
                  <table className="min-w-full">
                    <thead className="border-b border-gray-300 text-gray-900">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Ürün Bilgisi
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Ürün Adedi
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Ürün Fiyatı
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {payInfo.map((project, i) =>
                        project?.map((prod, j) => (
                          <tr key={j} className="border-b border-gray-200">
                            <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                              {prod.name}
                            </td>
                            <td className=" px-3 py-5 text-center text-sm text-gray-500">
                              {prod.quantity}
                            </td>
                            <td className="py-5 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-0">
                              {prod.price} ₺
                            </td>
                          </tr>
                        )),
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="mr-5 mt-5">
                  <div>
                    <div className="mr-2 my-2">
                      <select
                        onChange={text => {
                          setShipInfo({
                            ...shipInfo,
                            shipName: text.target.value,
                          });
                        }}
                        value={shipInfo?.shipName}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option>Sevk Firması Seç...</option>
                        <option>Aras Kargo</option>
                        <option>Sürat Kargo</option>
                        <option>Yurtiçi Kargo</option>
                      </select>
                    </div>
                    <div className="mr-2 my-2">
                      <input
                        type="text"
                        placeholder="Sevk Takip No"
                        required
                        value={shipInfo?.shipNo}
                        onChange={text => {
                          setShipInfo({
                            ...shipInfo,
                            shipNo: text.target.value,
                          });
                        }}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <button
                    onClick={e => handleShipInfo()}
                    className="w-full my-5 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:text-sm">
                    Sevk Bilgilerini Kaydet
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ReqCModal;
