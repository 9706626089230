import React, {useEffect, useState} from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/tr';
import ImageModal from './ImageModal';
import ReqModal from './ReqModal';

const Requests = () => {
  const [requests, setRequests] = useState([]);

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState('');

  const [reqOpen, setReqOpen] = useState(false);
  const [reqId, setReqId] = useState('');

  const [search, setSearch] = useState('');
  const [imageType, setImageType] = useState('');

  const getData = () => {
    axios
      .get(process.env.REACT_APP_API_URL + 'getRequest.php', {
        headers: {
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
      })
      .then(res => {
        setRequests(res.data.filter(req => req.isOffer === '0'));
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!reqOpen && imageType === '') {
      getData();
    }
  }, [reqOpen, imageType]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Kullanıcı Talepleri
          </h1>
        </div>
        <input
          type="search"
          placeholder="Ara..."
          className="w-full sm:w-64 mt-4 sm:mt-0 sm:ml-4 border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          onChange={e => setSearch(e.target.value)}
        />
      </div>
      <ImageModal
        open={open}
        setOpen={setOpen}
        image={image}
        type={imageType}
        setType={setImageType}
        reqId={reqId}
        setReqId={setReqId}
      />
      <ReqModal open={reqOpen} setOpen={setReqOpen} reqId={reqId} />
      <div className="-mx-4 mt-8 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                Kullanıcı
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                Araç Markası
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                Şasi No
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Parçalar
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Kayıt Tarihi
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                <span className="sr-only">Teklif Ver</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {requests
              ?.filter(
                req =>
                  !search.length ||
                  req.user
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()) ||
                  req.chassisNo
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()),
              )
              .map((reqs, index) => (
                <tr key={index} className="even:bg-gray-50">
                  <td className=" w-1/3 max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-3">
                    {reqs.user}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">Araç</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {reqs.brand}
                      </dd>
                      <dt className="sr-only sm:hidden">Şasi</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        {reqs.chassisNo.includes('.jpg') ||
                        reqs.chassisNo.includes('.jpeg') ||
                        reqs.chassisNo.includes('.png') ? (
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_DR + reqs.chassisNo
                            }
                            alt="ceppar"
                            className="h-14 w-14 rounded-full bg-gray-50 cursor-pointer"
                            onClick={() => {
                              setImage(
                                process.env.REACT_APP_IMAGE_DR + reqs.chassisNo,
                              );
                              setImageType('chassis');
                              setReqId(reqs.id);
                              setOpen(true);
                            }}
                          />
                        ) : (
                          reqs.chassisNo
                        )}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {reqs.brand}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {reqs.chassisNo.includes('.jpg') ||
                    reqs.chassisNo.includes('.jpeg') ||
                    reqs.chassisNo.includes('.png') ? (
                      <img
                        src={process.env.REACT_APP_IMAGE_DR + reqs.chassisNo}
                        alt="ceppar"
                        className="h-14 w-14 rounded-full bg-gray-50 cursor-pointer"
                        onClick={() => {
                          setImage(
                            process.env.REACT_APP_IMAGE_DR + reqs.chassisNo,
                          );
                          setReqId(reqs.id);
                          setImageType('chassis');
                          setOpen(true);
                        }}
                      />
                    ) : (
                      reqs.chassisNo
                    )}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {reqs.parts === '' ? reqs.partsFile : reqs.parts}
                    <div className="flex flex-row mt-1">
                      {reqs.partsImage &&
                        reqs.partsImage.split(',').map((image, index) => (
                          <img
                            key={index}
                            src={process.env.REACT_APP_IMAGE_DR + image}
                            alt="ceppar"
                            className="w-8 h-8 mr-1 rounded-lg bg-gray-50 cursor-pointer sm:h-14 sm:w-14 sm:rounded-full"
                            onClick={() => {
                              setImage(process.env.REACT_APP_IMAGE_DR + image);
                              setOpen(true);
                            }}
                          />
                        ))}
                    </div>
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {moment(reqs.record_date).format('llll')}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <button
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={() => {
                        setReqId(reqs.id);
                        setReqOpen(true);
                      }}>
                      Teklif Ver
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Requests;
