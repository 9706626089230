import {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import axios from 'axios';

const ReqVModal = props => {
  const [Vreq, setVreq] = useState({});

  useEffect(() => {
    setVreq({});
    axios
      .get(process.env.REACT_APP_API_URL + 'getOffers.php', {
        headers: {
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
        params: {
          reqId: props.reqId,
        },
      })
      .then(res => {
        setVreq(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [props.reqId]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg lg:ml-72 sm:max-w-6xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-24 sm:p-6">
                <div className="-mx-4 mt-8 flow-root sm:mx-0">
                  <table className="min-w-full">
                    <colgroup>
                      <col className="w-full sm:w-1/3" />
                    </colgroup>
                    <thead className="border-b border-gray-300 text-gray-900">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Ürün Bilgisi
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Ürün Adedi
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Orjinal Fiyat
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          L.Orjinal Fiyat
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Yan Sanayi Fiyat
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Çıkma Fiyat
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Vreq?.products?.map((project, i) => (
                        <tr key={i} className="border-b border-gray-200">
                          <td className="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                            <div className="flex flex-row items-center">
                              {project.productImage && (
                                <img
                                  src={
                                    process.env.REACT_APP_PRIMAGE_DR +
                                    project.productImage
                                  }
                                  alt="ceppar"
                                  className=" h-16 w-16 rounded-xl"
                                />
                              )}
                              <div className="ml-2">
                                <p className="text-sm text-gray-900">
                                  {project.productId}
                                </p>
                                <p className="text-sm text-gray-900">
                                  {project.productName}
                                </p>
                                <p className="text-sm text-gray-900">
                                  {project.productBrand}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className=" px-3 py-5 text-center text-sm text-gray-500">
                            {project.productQuantity}
                          </td>
                          <td className="py-5 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-0">
                            {project.productPrice}
                          </td>
                          <td className="py-5 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-0">
                            {project.productLPrice}
                          </td>
                          <td className="py-5 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-0">
                            {project.productYPrice}
                          </td>
                          <td className="py-5 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-0">
                            {project.productCPrice}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="mr-5 mt-5 text-right">
                  <button
                    onClick={() => {}}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:text-sm">
                    Teklifi Yazdır
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ReqVModal;
