import React, {useEffect, useState} from 'react';
import axios from 'axios';
import UserModal from './UserModal';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!open) {
      setUsers([]);
      setUserInfo({});
      axios
        .get(process.env.REACT_APP_API_URL + 'getUsers.php', {
          headers: {
            Token: process.env.REACT_APP_SECRET_TOKEN,
          },
        })
        .then(res => {
          setUsers(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [open]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Kullanıcılar
          </h1>
        </div>
        <input
          type="search"
          placeholder="Ara..."
          className="w-full sm:w-64 mt-4 sm:mt-0 sm:ml-4 border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          onChange={e => setSearch(e.target.value)}
        />
      </div>
      <UserModal open={open} setOpen={setOpen} userInfo={userInfo} />
      <div className="-mx-4 mt-8 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                İsim
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                Email
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                Telefon
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Kayıt Tarihi
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                <span className="sr-only">Düzenle</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {users
              .filter(
                person =>
                  !search.length ||
                  person.name
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()) ||
                  person.email
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()),
              )
              .map((person, index) => (
                <tr key={index} className="even:bg-gray-50">
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-3">
                    {person.name}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">Email</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {person.email}
                      </dd>
                      <dt className="sr-only sm:hidden">Phone</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        {person.phone}
                      </dd>
                    </dl>
                  </td>
                  <td
                    className={
                      person.ban === '0'
                        ? 'hidden px-3 py-4 text-sm text-gray-500 lg:table-cell'
                        : 'hidden px-3 py-4 text-sm text-red-600 lg:table-cell'
                    }>
                    {person.email}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {person.phone}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {person.record_date}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <button
                      onClick={() => {
                        setUserInfo(person);
                        setOpen(true);
                      }}
                      className={
                        person.ban === '0'
                          ? 'text-indigo-600 hover:text-indigo-900'
                          : 'text-red-600 hover:text-indigo-900'
                      }>
                      Düzenle
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
