import React, {useEffect, useState} from 'react';
import axios from 'axios';
import ProductsModal from './ProductsModal';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [prodInfo, setProdInfo] = useState({});
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!open) {
      setProducts([]);
      setProdInfo({});
      axios
        .get(process.env.REACT_APP_API_URL + 'getProducts.php', {
          headers: {
            Token: process.env.REACT_APP_SECRET_TOKEN,
          },
        })
        .then(res => {
          setProducts(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [open]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Ürünler
          </h1>
        </div>
        <button
          className="hidden sm:block ml-4 bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => {
            setProdInfo({
              productId: '',
              productName: '',
              productBrand: '',
              productImage: '',
              productImageFile: '',
              productPrice: '',
              productLPrice: '',
              productYPrice: '',
              productCPrice: '',
            });
            setOpen(true);
          }}>
          Ekle
        </button>
        <input
          type="search"
          placeholder="Ara..."
          className="w-full sm:w-64 mt-4 sm:mt-0 sm:ml-4 border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          onChange={e => setSearch(e.target.value)}
        />
      </div>
      <ProductsModal open={open} setOpen={setOpen} prodInfo={prodInfo} />
      <div className="-mx-4 mt-8 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                ID
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                Marka
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                Adı
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Fotoğraf
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Orjinal Fiyat
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                L.Orjinal Fiyat
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Yan Sanayi Fiyat
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Çıkma Fiyat
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                <span className="sr-only">Düzenle</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {products
              .filter(
                prods =>
                  !search.length ||
                  prods.productId
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()) ||
                  prods.productName
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()),
              )
              .map((prods, index) => (
                <tr key={index} className="even:bg-gray-50">
                  <td className="w-full py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-3">
                    {prods.productId}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">Marka</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {prods.productBrand}
                      </dd>
                      <dt className="sr-only sm:hidden">Adı</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        {prods.productName}
                      </dd>
                    </dl>
                  </td>
                  <td
                    className={
                      'hidden px-3 py-4 text-sm text-gray-600 lg:table-cell'
                    }>
                    {prods.productBrand}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {prods.productName}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {prods.productImage === '' ? (
                      'Ürün resmi yok.'
                    ) : (
                      <img
                        src={
                          process.env.REACT_APP_PRIMAGE_DR + prods.productImage
                        }
                        alt={prods.name}
                        className="h-14 w-14 rounded-full bg-gray-50 cursor-pointer"
                      />
                    )}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {prods.productPrice} ₺
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {prods.productLPrice} ₺
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {prods.productYPrice} ₺
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {prods.productCPrice} ₺
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <button
                      onClick={() => {
                        setProdInfo(prods);
                        setOpen(true);
                      }}
                      className={'text-indigo-600 hover:text-indigo-900'}>
                      Düzenle
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Products;
