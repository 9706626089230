import React, {useState} from 'react';
import Modal from './dashScreen/Modal';

const DelUser = () => {
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
  });
  const [open, setOpen] = useState(false);

  const handleLogin = e => {
    e.preventDefault();
    setOpen(true);
    setUserInfo({
      email: '',
      password: '',
    });
  };

  return (
    <div className="h-screen bg-white">
      <div className="flex min-h-full flex-1">
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1593510987046-1f8fcfc512a0?q=80&w=2970&auto=format&fit=crop"
            alt=""
          />
        </div>
        <Modal
          open={open}
          setOpen={setOpen}
          text={{
            title: 'Hesap Bilgileri Silme İşlemi',
            desc: 'Hesabınızı ve bilgilerinizi silmek için gönderdiğiniz istek işleme alınmıştır. En kısa sürede isteğiniz gerçekleştirilecektir.',
          }}
        />
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-8 text-3xl font-bold leading-9 tracking-tight text-black">
                CEPPAR HESAP SİLME
              </h2>
            </div>

            <div className="mt-10">
              <div>
                <form onSubmit={handleLogin} className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      E-Mail
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="text"
                        value={userInfo.email}
                        onChange={e => {
                          setUserInfo({...userInfo, email: e.target.value});
                        }}
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Şifre
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        value={userInfo.password}
                        onChange={e =>
                          setUserInfo({...userInfo, password: e.target.value})
                        }
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-red-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                      Hesabımı Sil
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DelUser;
