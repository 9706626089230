import Users from './screens/dashScreen/Users';
import Requests from './screens/dashScreen/Requests';
import AwaitRequests from './screens/dashScreen/AwaitRequests';
import CompRequests from './screens/dashScreen/CompRequests';
import Products from './screens/dashScreen/Products';
import Cars from './screens/dashScreen/Cars';
import {
  ClockIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
  UsersIcon,
  TableCellsIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/solid';

const routes = [
  {
    path: 'users',
    element: <Users />,
    icon: UsersIcon,
    name: 'Kullanıcılar',
  },
  {
    path: 'in_request',
    element: <Requests />,
    icon: DocumentTextIcon,
    name: 'Gelen İstekler',
  },
  {
    path: 'on_request',
    element: <AwaitRequests />,
    icon: ClockIcon,
    name: 'Bekleyen Teklifler',
  },
  {
    path: 'ok_request',
    element: <CompRequests />,
    icon: DocumentCheckIcon,
    name: 'Tamamlanan Teklifler',
  },
  {
    path: 'products',
    element: <Products />,
    icon: TableCellsIcon,
    name: 'Ürünler',
  },
  {
    path: 'cars',
    element: <Cars />,
    icon: RocketLaunchIcon,
    name: 'Araçlar',
  },
];

export default routes;
