import {Fragment, useEffect, useState, useRef} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import axios from 'axios';
import {useReactToPrint} from 'react-to-print';

const ReqCUserModal = props => {
  const [payInfo, setPayInfo] = useState([]);

  const [userInfo, setUserInfo] = useState({
    name: '',
    phone: '',
    adress: '',
  });

  const getUserInfo = async () => {
    setUserInfo({
      name: '',
      phone: '',
      adress: '',
    });
    await axios
      .get(process.env.REACT_APP_API_URL + 'getUserInfo.php', {
        headers: {
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
        params: {
          uMail: props?.user,
        },
      })
      .then(res => {
        setUserInfo({
          name: res?.data[0]?.name,
          phone: res?.data[0]?.phone,
          adress: res?.data[0]?.adress,
        });
      });
  };

  const ref = useRef(null);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + 'getModalInfo.php', {
        headers: {
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
        params: {
          reqId: props?.reqId,
        },
      })
      .then(res => {
        setPayInfo(res.data);
      })
      .catch(err => {
        console.log(err);
      });
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reqId]);

  const handleShipInfo = useReactToPrint({
    pageStyle: `@page {
        size: 210mm 297mm;
      }`,
    content: () => ref.current,
  });

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg lg:ml-72 sm:max-w-6xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-24 sm:p-6">
                <div className="-mx-4 mt-8 flow-root sm:mx-0" ref={ref}>
                  <div className="mb-3 border-b pb-2">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      İsim : {userInfo?.name}
                    </h3>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Telefon : {userInfo?.phone}
                    </h3>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Adres : {userInfo?.adress}
                    </h3>
                  </div>
                  <table className="min-w-full">
                    <thead className="border-b border-gray-300 text-gray-900">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Ürün Bilgisi
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Ürün Adedi
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Ürün Fiyatı
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {payInfo.map((project, i) =>
                        project?.map((prod, j) => (
                          <tr key={j} className="border-b border-gray-200">
                            <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                              {prod.name}
                            </td>
                            <td className=" px-3 py-5 text-center text-sm text-gray-500">
                              {prod.quantity}
                            </td>
                            <td className="py-5 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-0">
                              {prod.price} ₺
                            </td>
                          </tr>
                        )),
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="mr-5 mt-5">
                  <button
                    onClick={() => {
                      handleShipInfo();
                      props.setOpen(false);
                    }}
                    className="w-full my-5 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:text-sm">
                    Yazdır
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ReqCUserModal;
