import {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import Select from 'react-select';

const ReqModal = props => {
  const [reqInfo, setReqInfo] = useState({
    productId: '',
    productName: '',
    productBrand: '',
    productImage: '',
    productImageFile: '',
    productQuantity: '',
    productPrice: '',
    productLPrice: '',
    productYPrice: '',
    productCPrice: '',
  });

  const [projects, setProjects] = useState([]);

  const [products, setProducts] = useState([]);

  const projLength = projects.length === 0;

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + 'getProducts.php', {
        headers: {
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
      })
      .then(res => {
        setProducts(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [projLength]);

  useEffect(() => {
    setReqInfo({
      productId: '',
      productName: '',
      productBrand: '',
      productImage: '',
      productImageFile: '',
      productQuantity: '',
      productPrice: '',
      productLPrice: '',
      productYPrice: '',
      productCPrice: '',
    });
    setProjects([]);
  }, [props.reqId]);

  const handleAddProduct = e => {
    e.preventDefault();

    setProjects([...projects, reqInfo]);
    setReqInfo({
      productId: '',
      productName: '',
      productBrand: '',
      productImage: '',
      productImageFile: '',
      productQuantity: '',
      productPrice: '',
      productLPrice: '',
      productYPrice: '',
      productCPrice: '',
    });
  };

  const ResizerFile = (file, result) => {
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      'jpeg',
      50,
      0,
      uri => {
        setReqInfo({
          ...reqInfo,
          productImage: result,
          productImageFile: uri,
        });
      },
      'file',
    );
  };

  const saveOffer = () => {
    let fromdata = new FormData();
    fromdata.append('reqId', props.reqId);

    const newProjects = [];
    projects.forEach(project => {
      const newProject = {
        productId: project.productId,
        productName: project.productName,
        productBrand: project.productBrand,
        productQuantity: project.productQuantity,
        productPrice: project.productPrice,
        productLPrice: project.productLPrice,
        productYPrice: project.productYPrice,
        productCPrice: project.productCPrice,
      };
      newProjects.push(newProject);
      fromdata.append(`${project.productId}file`, project.productImageFile);
    });

    fromdata.append('products', JSON.stringify(newProjects));

    axios
      .post(process.env.REACT_APP_API_URL + 'saveOffer.php', fromdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Token: process.env.REACT_APP_SECRET_TOKEN,
        },
      })
      .then(res => {
        if (res.data.status === 'Success') {
          alert('Teklifiniz kaydedildi.');
          setProjects([]);
          props.setOpen(false);
        } else {
          alert(res.data.message);
        }
      });
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg lg:ml-72 sm:max-w-6xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-24 sm:p-6">
                <div className="border border-dashed border-gray-900/25 px-4 py-4 mr-2">
                  <div className="mr-2 mt-2">
                    <Select
                      className="basic-single"
                      classNamePrefix={'select'}
                      isSearchable={true}
                      options={products.map((product, i) => ({
                        value: product.productId,
                        label:
                          product.productId +
                          ' - ' +
                          product.productBrand +
                          ' ' +
                          product.productName,
                      }))}
                      placeholder="Ürün Kodu Seç..."
                      onChange={text => {
                        products.filter(product => {
                          if (product.productId === text.value) {
                            setReqInfo({
                              ...reqInfo,
                              productId: product.productId,
                              productName: product.productName,
                              productBrand: product.productBrand,
                              productPrice: product.productPrice,
                              productLPrice: product.productLPrice,
                              productYPrice: product.productYPrice,
                              productCPrice: product.productCPrice,
                              productImage:
                                process.env.REACT_APP_PRIMAGE_DR +
                                product.productImage,
                            });
                          }
                          return null;
                        });
                      }}
                    />
                  </div>
                  <form onSubmit={handleAddProduct}>
                    <div className="mt-3 text-center flex flex-row justify-between items-center sm:mt-5">
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Ürün Kodu
                        </label>
                        <input
                          type="text"
                          placeholder="Ürün Kodu"
                          value={reqInfo.productId}
                          min={0}
                          required
                          onChange={text => {
                            setReqInfo({
                              ...reqInfo,
                              productId: text.target.value,
                            });
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Ürün Adı
                        </label>
                        <input
                          type="text"
                          placeholder="Ürün Adı"
                          required
                          value={reqInfo.productName}
                          onChange={text => {
                            setReqInfo({
                              ...reqInfo,
                              productName: text.target.value,
                            });
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Ürün Markası
                        </label>
                        <input
                          type="text"
                          placeholder="Ürün Markası"
                          required
                          value={reqInfo.productBrand}
                          onChange={text => {
                            setReqInfo({
                              ...reqInfo,
                              productBrand: text.target.value,
                            });
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="mr-2 mt-2 w-full">
                        <label className="text-indigo-600 cursor-pointer ml-2">
                          Foto Yükle
                          <input
                            type="file"
                            className="sr-only"
                            onChange={e => {
                              if (e.target.files && e.target.files[0]) {
                                let reader = new FileReader();
                                let rFile = e.target.files[0];
                                reader.onloadend = () => {
                                  ResizerFile(rFile, reader.result);
                                };
                                reader.readAsDataURL(rFile);
                              }
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="mt-3 text-center flex flex-row justify-between sm:mt-5">
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Ürün Adedi
                        </label>
                        <input
                          type="number"
                          placeholder="Ürün Adedi"
                          value={reqInfo.productQuantity}
                          min={0}
                          required
                          onChange={text => {
                            setReqInfo({
                              ...reqInfo,
                              productQuantity: text.target.value,
                            });
                          }}
                          onKeyDown={e => {
                            if (
                              e.key === 'E' ||
                              e.key === 'e' ||
                              e.key === ',' ||
                              e.key === '-' ||
                              e.key === '+'
                            ) {
                              e.preventDefault();
                            }
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Orjinal Fiyat
                        </label>
                        <input
                          type="number"
                          placeholder="Orjinal Fiyat"
                          value={reqInfo.productPrice}
                          min={0}
                          onChange={text => {
                            setReqInfo({
                              ...reqInfo,
                              productPrice: text.target.value,
                            });
                          }}
                          onKeyDown={e => {
                            if (
                              e.key === 'E' ||
                              e.key === 'e' ||
                              e.key === ',' ||
                              e.key === '-' ||
                              e.key === '+'
                            ) {
                              e.preventDefault();
                            }
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          L.Orjinal Fiyat
                        </label>
                        <input
                          type="number"
                          placeholder="L.Orjinal Fiyat"
                          value={reqInfo.productLPrice}
                          min={0}
                          onChange={text => {
                            setReqInfo({
                              ...reqInfo,
                              productLPrice: text.target.value,
                            });
                          }}
                          onKeyDown={e => {
                            if (
                              e.key === 'E' ||
                              e.key === 'e' ||
                              e.key === ',' ||
                              e.key === '-' ||
                              e.key === '+'
                            ) {
                              e.preventDefault();
                            }
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Yan Sanayi Fiyat
                        </label>
                        <input
                          type="number"
                          placeholder="Yan Sanayi Fiyat"
                          value={reqInfo.productYPrice}
                          min={0}
                          onChange={text => {
                            setReqInfo({
                              ...reqInfo,
                              productYPrice: text.target.value,
                            });
                          }}
                          onKeyDown={e => {
                            if (
                              e.key === 'E' ||
                              e.key === 'e' ||
                              e.key === ',' ||
                              e.key === '-' ||
                              e.key === '+'
                            ) {
                              e.preventDefault();
                            }
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="mr-2 mt-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Çıkma Fiyat
                        </label>
                        <input
                          type="number"
                          placeholder="Çıkma Fiyat"
                          value={reqInfo.productCPrice}
                          min={0}
                          onChange={text => {
                            setReqInfo({
                              ...reqInfo,
                              productCPrice: text.target.value,
                            });
                          }}
                          onKeyDown={e => {
                            if (
                              e.key === 'E' ||
                              e.key === 'e' ||
                              e.key === ',' ||
                              e.key === '-' ||
                              e.key === '+'
                            ) {
                              e.preventDefault();
                            }
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="mr-2 mt-2 text-center">
                      <button
                        type="submit"
                        className="my-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:text-sm">
                        Ekle
                      </button>
                    </div>
                  </form>
                </div>
                <div className="-mx-4 mt-8 flow-root sm:mx-0">
                  <table className="min-w-full">
                    <colgroup>
                      <col className="w-full sm:w-1/3" />
                    </colgroup>
                    <thead className="border-b border-gray-300 text-gray-900">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Ürün Bilgisi
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Ürün Adedi
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Orjinal Fiyat
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          L.Orjinal Fiyat
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Yan Sanayi Fiyat
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Çıkma Fiyat
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4">
                          <span className="sr-only">Sil</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((project, i) => (
                        <tr key={i} className="border-b border-gray-200">
                          <td className="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                            <div className="flex flex-row items-center">
                              {project.productImage && (
                                <img
                                  src={project.productImage}
                                  alt="ceppar"
                                  className=" h-16 w-16 rounded-xl"
                                />
                              )}
                              <div className="ml-2">
                                <p className="text-sm text-gray-900">
                                  {project.productId}
                                </p>
                                <p className="text-sm text-gray-900">
                                  {project.productName}
                                </p>
                                <p className="text-sm text-gray-900">
                                  {project.productBrand}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className=" px-3 py-5 text-center text-sm text-gray-500">
                            {project.productQuantity}
                          </td>
                          <td className="py-5 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-0">
                            {project.productPrice}
                          </td>
                          <td className="py-5 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-0">
                            {project.productLPrice}
                          </td>
                          <td className="py-5 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-0">
                            {project.productYPrice}
                          </td>
                          <td className="py-5 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-0">
                            {project.productCPrice}
                          </td>
                          <td className="py-5 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-0">
                            <button
                              onClick={() => {
                                setProjects(
                                  projects.filter(
                                    proj =>
                                      proj.productId !== project.productId,
                                  ),
                                );
                              }}
                              className="text-red-600 hover:text-red-900">
                              Sil
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="mr-5 mt-5 text-right">
                  <button
                    onClick={() => {
                      saveOffer();
                    }}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:text-sm">
                    Teklifi Kaydet
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ReqModal;
